import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout/Layout';
import { slugify } from '../utils/Misc';

const PaymentPolicy = () => {
  return (
    <Layout>
      <Helmet title="Payment Policy | E-Warz Tournaments" />
      <section className="clean-block features bg-dark-alt text-white pb-lg-5">
        <div className="container">
          <div className="block-heading">
            <h2 className="text-info">Payment Policy</h2>

            {/* <p>
              following are the 
            </p> */}
          </div>
          <div>
            <p class="c2">
              <span class="c5">Welcome to </span>
              <span class="c3 c12">IYG Entertainment Private Limited</span>
              <span class="c4">
                &nbsp;(&ldquo;Company&rdquo;, &ldquo;we&rdquo;, &ldquo;our&rdquo;,
                &ldquo;us&rdquo;)!
              </span>
            </p>
            <p class="c2">
              <span class="c5">
                These Terms of Service (&ldquo;Terms&rdquo;, &ldquo;Terms of Service&rdquo;) govern
                your use of our website located at{' '}
              </span>
              <span class="c3 c9 c17">
                <a class="c6" href="#">
                  ewarz.org
                </a>
              </span>
              <span class="c5">
                &nbsp;(together or individually &ldquo;Service&rdquo;) operated by{' '}
              </span>
              <span class="c3 c12">IYG Entertainment Private Limited</span>
              <span class="c4">.</span>
            </p>
            <p class="c1">
              <span class="c4">
                Our Privacy Policy also governs your use of our Service and explains how we collect,
                safeguard and disclose information that results from your use of our web pages.
              </span>
            </p>
            <p class="c1">
              <span class="c4">
                Your agreement with us includes these Terms and our Privacy Policy
                (&ldquo;Agreements&rdquo;). You acknowledge that you have read and understood
                Agreements, and agree to be bound by them.
              </span>
            </p>
            <p class="c2">
              <span class="c5">
                If you do not agree with (or cannot comply with) Agreements, then you may not use
                the Service, but please let us know by emailing{' '}
              </span>
              <span class="c3 c9">support@ewarz.freshdesk.com</span>
              <span class="c4">
                &nbsp;so we can try to find a solution. These Terms apply to all visitors, users and
                others who wish to access or use Service.
              </span>
            </p>
            <p class="c2">
              <span class="c4">&nbsp;</span>
            </p>
            <p class="c2">
              <span class="c5">2. </span>
              <span class="c0">Purchases</span>
            </p>
            <p class="c1">
              <span class="c4">
                If you wish to purchase any product or service made available through Service
                (&ldquo;Purchase&rdquo;), you may be asked to supply certain information relevant to
                your Purchase including but not limited to, your credit or debit card number, the
                expiration date of your card, your billing address, and your shipping information.
              </span>
            </p>
            <p class="c1">
              <span class="c4">
                You represent and warrant that: (i) you have the legal right to use any card(s) or
                another payment method (s) in connection with any Purchase; and that (ii) the
                information you supply to us is true, correct and complete.
              </span>
            </p>
            <p class="c1">
              <span class="c4">
                We may employ the use of third-party services to facilitate payment and the
                completion of Purchases. By submitting your information, you grant us the right to
                provide the information to these third parties subject to our Privacy Policy.
              </span>
            </p>
            <p class="c1">
              <span class="c4">
                We reserve the right to refuse or cancel your order at any time for reasons
                including but not limited to product or service availability, errors in the
                description or price of the product or service, error in your order or other
                reasons.
              </span>
            </p>
            <p class="c1">
              <span class="c4">
                We reserve the right to refuse or cancel your order if fraud or an unauthorized or
                illegal transaction is suspected.
              </span>
            </p>
            <p class="c2">
              <span class="c5">4. </span>
              <span class="c0">Contests, Sweepstakes and Promotions</span>
            </p>
            <p class="c1">
              <span class="c4">
                Any contests, sweepstakes or other promotions (collectively,
                &ldquo;Promotions&rdquo;) made available through Service may be governed by rules
                that are separate from these Terms of Service. If you participate in any Promotions,
                please review the applicable rules as well as our Privacy Policy. If the rules for a
                Promotion conflict with these Terms of Service, Promotion rules will apply.
              </span>
            </p>
            <span class="c4">&nbsp;</span>
            <p class="c2">
              <span class="c5">5. </span>
              <span class="c0">Refunds</span>
            </p>
            <p class="c2">
              <span class="c4">5.1 We issue a refund If any tournament or event will cancel</span>
            </p>
            <p class="c2">
              <span class="c4">5.2 We don&rsquo;t issue a refund if any user backoff</span>
            </p>
            <p class="c2">
              <span class="c4">
                5.3 We don&rsquo;t issue any refund of any other services &nbsp;
              </span>
            </p>
            <p class="c2">
              <span class="c4">&nbsp;</span>
            </p>
            <p class="c2">
              <span class="c5">6. </span>
              <span class="c0">Content</span>
            </p>
            <p class="c2">
              <span class="c5">The content</span>
              <span class="c4">
                &nbsp;found on or through this Service is the property of IYG Entertainment Private
                Limited or used with permission. You may not distribute, modify, transmit, reuse,
                download, repost, copy, or use said Content, whether in whole or in part, for
                commercial purposes or personal gain, without express advance written permission
                from us.
              </span>
            </p>
            <p class="c2">
              <span class="c4">&nbsp;</span>
            </p>
            <p class="c2">
              <span class="c5">7. </span>
              <span class="c0">Prohibited Uses</span>
            </p>
            <p class="c1">
              <span class="c4">
                You may use Service only for lawful purposes and per Terms. You agree not to use
                Service:
              </span>
            </p>
            <p class="c1">
              <span class="c4">
                0.1. In any way that violates any applicable national or international law or
                regulation.
              </span>
            </p>
            <p class="c1">
              <span class="c4">
                0.2. to exploit, harm, or attempt to exploit or harm minors in any way by exposing
                them to inappropriate content or otherwise.
              </span>
            </p>
            <p class="c1">
              <span class="c4">
                0.3. To transmit, or procure the sending of, any advertising or promotional
                material, including any &ldquo;junk mail&rdquo;, &ldquo;chain letter,&rdquo;
                &ldquo;spam,&rdquo; or any other similar solicitation.
              </span>
            </p>
            <p class="c1">
              <span class="c4">
                0.4. To impersonate or attempt to impersonate Company, a Company employee, another
                user, or any other person or entity.
              </span>
            </p>
            <p class="c1">
              <span class="c4">
                0.5. In any way that infringes upon the rights of others, or in any way is illegal,
                threatening, fraudulent, or harmful, or in connection with any unlawful, illegal,
                fraudulent, or harmful purpose or activity.
              </span>
            </p>
            <p class="c1">
              <span class="c4">
                0.6. To engage in any other conduct that restricts or inhibits anyone&rsquo;s use or
                enjoyment of Service, or which, as determined by us, may harm or offend Company or
                users of Service or expose them to liability.
              </span>
            </p>
            <p class="c1">
              <span class="c4">&nbsp;</span>
            </p>
            <p class="c1">
              <span class="c4">
                0.7 Any user located in the restricted state in India can&rsquo;t use our services
              </span>
            </p>
            <p class="c1">
              <span class="c5">0.8 </span>
              <span class="c8 c7">
                In India, online gaming is not prohibited. Online gaming with stakes or real money
                is allowed in most states however as per the government guidelines we are unable to
                provide any of our services in
              </span>
            </p>
            <p class="c15">
              <span class="c3">Andhra Pradesh, Arunachal Pradesh</span>
              <span class="c7">, </span>
              <span class="c3">Telangana</span>
              <span class="c7">, </span>
              <span class="c3">Odisha</span>
              <span class="c7">&nbsp;and </span>
              <span class="c3 c16">Assam</span>
            </p>
            <p class="c15">
              <span class="c7 c8">&nbsp;</span>
            </p>
            <p class="c1">
              <span class="c4">
                0.1. Use Service in any manner that could disable, overburden, damage, or impair
                Service or interfere with any other party&rsquo;s use of Service, including their
                ability to engage in real-time activities through Service.
              </span>
            </p>
            <p class="c1">
              <span class="c4">
                0.2. Use any robot, spider, or another automatic device, process, or means to access
                Service for any purpose, including monitoring or copying any of the material on
                Service.
              </span>
            </p>
            <p class="c1">
              <span class="c4">
                0.3. Use any manual process to monitor or copy any of the material on Service or for
                any other unauthorized purpose without our prior written consent.
              </span>
            </p>
            <p class="c1">
              <span class="c4">
                0.4. Use any device, software, or routine that interferes with the proper working of
                the Service.
              </span>
            </p>
            <p class="c1">
              <span class="c4">
                0.5. Introduce any viruses, trojan horses, worms, logic bombs, or other material
                which is malicious or technologically harmful.
              </span>
            </p>
            <p class="c1">
              <span class="c4">
                0.6. Attempt to gain unauthorized access to, interfere with, damage, or disrupt any
                parts of Service, the server on which Service is stored, or any server, computer, or
                database connected to Service.
              </span>
            </p>
            <p class="c1">
              <span class="c4">
                0.7. Attack Service via a denial-of-service attack or a distributed
                denial-of-service attack.
              </span>
            </p>
            <p class="c1">
              <span class="c4">
                0.8. Take any action that may damage or falsify Company&#39;s rating.
              </span>
            </p>
            <p class="c1">
              <span class="c4">
                0.9. Otherwise attempt to interfere with the proper working of Service.
              </span>
            </p>
            <p class="c2">
              <span class="c5">8. </span>
              <span class="c0">Analytics</span>
            </p>
            <p class="c1">
              <span class="c4">
                We may use third-party Service Providers to monitor and analyse the use of our
                Service.
              </span>
            </p>
            <p class="c2">
              <span class="c5">9. </span>
              <span class="c0">No Use By Minors</span>
            </p>
            <p class="c1">
              <span class="c4">
                Service is intended only for access and use by individuals at least eighteen (18)
                years old. By accessing or using Service, you warrant and represent that you are at
                least eighteen (18) years of age and with the full authority, right, and capacity to
                enter into this agreement and abide by all of the terms and conditions of the Terms.
                If you are not at least eighteen (18) years old, you are prohibited from both access
                and usage of the Service.
              </span>
            </p>
            <p class="c2">
              <span class="c5">10. </span>
              <span class="c0">Intellectual Property</span>
            </p>
            <p class="c2">
              <span class="c4">
                Service and its original content (excluding Content provided by users), features and
                functionality are and will remain the exclusive property of IYG Entertainment
                Private Limited and its licensors. Service is protected by copyright, trademark, and
                other laws of foreign countries. Our trademarks may not be used in connection with
                any product or service without the prior written consent of IYG Entertainment
                Private Limited.
              </span>
            </p>
            <p class="c2">
              <span class="c4">&nbsp;</span>
            </p>
            <p class="c2">
              <span class="c5">11. </span>
              <span class="c0">Copyright Policy</span>
            </p>
            <p class="c1">
              <span class="c4">
                We respect the intellectual property rights of others. It is our policy to respond
                to any claim that Content posted on Service infringes on the copyright or other
                intellectual property rights (&ldquo;Infringement&rdquo;) of any person or entity.
              </span>
            </p>
            <p class="c2">
              <span class="c5">
                If you are a copyright owner or authorized on behalf of one, and you believe that
                the copyrighted work has been copied in a way that constitutes copyright
                infringement, please submit your claim via email to{' '}
              </span>
              <span class="c7 c9">support@ewarz.freshdesk.com</span>
              <span class="c4">
                , with the subject line: &ldquo;Copyright Infringement&rdquo; and include in your
                claim a detailed description of the alleged Infringement as detailed below, under
                &ldquo;DMCA Notice and Procedure for Copyright Infringement Claims&rdquo;
              </span>
            </p>
            <p class="c1">
              <span class="c4">
                You may be held accountable for damages (including costs and attorneys&rsquo; fees)
                for misrepresentation or bad-faith claims on the infringement of any Content found
                on and/or through Service on your copyright.
              </span>
            </p>
            <p class="c2">
              <span class="c5">12. </span>
              <span class="c0">DMCA Notice and Procedure for Copyright Infringement Claims</span>
            </p>
            <p class="c1">
              <span class="c4">
                You may submit a notification according to the Digital Millennium Copyright Act
                (DMCA) by providing our Copyright Agent with the following information in writing
                (see 17 U.S.C 512(c)(3) for further detail):
              </span>
            </p>
            <p class="c1">
              <span class="c4">
                0.1. an electronic or physical signature of the person authorized to act on behalf
                of the owner of the copyright&rsquo;s interest;
              </span>
            </p>
            <p class="c1">
              <span class="c4">
                0.2. a description of the copyrighted work that you claim has been infringed,
                including the URL (i.e., web page address) of the location where the copyrighted
                work exists or a copy of the copyrighted work;
              </span>
            </p>
            <p class="c1">
              <span class="c4">
                0.3. identification of the URL or other specific location on Service where the
                material that you claim is infringing is located;
              </span>
            </p>
            <p class="c1">
              <span class="c4">0.4. your address, telephone number, and email address;</span>
            </p>
            <p class="c1">
              <span class="c4">
                0.5. a statement by you that you have a good faith belief that the disputed use is
                not authorized by the copyright owner, its agent, or the law;
              </span>
            </p>
            <p class="c1">
              <span class="c4">
                0.6. a statement by you, made under penalty of perjury, that the above information
                in your notice is accurate and that you are the copyright owner or authorized to act
                on the copyright owner&rsquo;s behalf.
              </span>
            </p>
            <p class="c2">
              <span class="c5">You can contact our Copyright Agent via email at </span>
              <span class="c7 c9">support@ewarz.freshdesk.com</span>
              <span class="c4">.</span>
            </p>
            <p class="c2">
              <span class="c4">&nbsp;</span>
            </p>
            <p class="c2">
              <span class="c5">13. </span>
              <span class="c0">Error Reporting and Feedback</span>
            </p>
            <p class="c2">
              <span class="c5">You may provide us either directly at </span>
              <span class="c7 c9">support@ewarz.freshdesk.com</span>
              <span class="c4">
                &nbsp;or via third-party sites and tools with information and feedback concerning
                errors, suggestions for improvements, ideas, problems, complaints, and other matters
                related to our Service (&ldquo;Feedback&rdquo;). You acknowledge and agree that: (i)
                you shall not retain, acquire or assert any intellectual property right or another
                right, title or interest in or to the Feedback; (ii) Company may have developed
                ideas similar to the Feedback; (iii) Feedback does not contain confidential
                information or proprietary information from you or any third party, and (iv) Company
                is not under any obligation of confidentiality concerning the Feedback. In the event
                the transfer of the ownership to the Feedback is not possible due to applicable
                mandatory laws, you grant Company and its affiliates an exclusive, transferable,
                irrevocable, free-of-charge, sub-licensable, unlimited and perpetual right to use
                (including copy, modify, create derivative works, publish, distribute and
                commercialize) Feedback in any manner and for any purpose.
              </span>
            </p>
            <p class="c2">
              <span class="c4">&nbsp;</span>
            </p>
            <p class="c2">
              <span class="c5">14. </span>
              <span class="c0">Links to Other Web Sites</span>
            </p>
            <p class="c2">
              <span class="c4">
                Our Service may contain links to third-party websites or services that are not owned
                or controlled by IYG Entertainment Private Limited.
              </span>
            </p>
            <p class="c2">
              <span class="c4">
                IYG Entertainment Private Limited has no control over and assumes no responsibility
                for the content, privacy policies, or practices of any third-party websites or
                services. We do not warrant the offerings of any of these entities/individuals or
                their websites.
              </span>
            </p>
            <p class="c2">
              <span class="c4">
                is an easy-to-use free tool for creating an excellent standard Terms of Service
                template for a website, blog, e-commerce store or app.
              </span>
            </p>
            <p class="c1">
              <span class="c4">
                YOU ACKNOWLEDGE AND AGREE THAT THE COMPANY SHALL NOT BE RESPONSIBLE OR LIABLE,
                DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR
                IN CONNECTION WITH THE USE OF OR RELIANCE ON ANY SUCH CONTENT, GOODS OR SERVICES
                AVAILABLE ON OR THROUGH ANY SUCH THIRD-PARTY WEB SITES OR SERVICES.
              </span>
            </p>
            <p class="c1">
              <span class="c4">
                WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY POLICIES OF ANY
                THIRD-PARTY WEBSITES OR SERVICES THAT YOU VISIT.
              </span>
            </p>
            <p class="c2">
              <span class="c5">15. </span>
              <span class="c0">Disclaimer of Warranty</span>
            </p>
            <p class="c1">
              <span class="c4">
                THESE SERVICES ARE PROVIDED BY THE COMPANY ON AN &ldquo;AS IS&rdquo; AND &ldquo;AS
                AVAILABLE&rdquo; BASIS. THE COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY
                KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES, OR THE INFORMATION,
                CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF THESE
                SERVICES, THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE
                RISK.
              </span>
            </p>
            <p class="c1">
              <span class="c4">
                NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR
                REPRESENTATION CONCERNING THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY,
                ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER
                COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE
                SERVICES, THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL
                BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED,
                THAT THE SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER
                HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH
                THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
              </span>
            </p>
            <p class="c1">
              <span class="c4">
                THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
                STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
                MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR A PARTICULAR PURPOSE.
              </span>
            </p>
            <p class="c1">
              <span class="c4">
                THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CAN NOT BE EXCLUDED OR LIMITED
                UNDER APPLICABLE LAW.
              </span>
            </p>
            <p class="c2">
              <span class="c5">16. </span>
              <span class="c0">Limitation Of Liability</span>
            </p>
            <p class="c1">
              <span class="c4">
                EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS, DIRECTORS,
                EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR
                CONSEQUENTIAL DAMAGE, HOWEVER, IT ARISES (INCLUDING ATTORNEYS&rsquo; FEES AND ALL
                RELATED COSTS AND EXPENSES OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL,
                IF ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN
                ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN
                CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM FOR PERSONAL
                INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY YOU OF
                ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF COMPANY
                HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED
                BY LAW, IF THERE IS LIABILITY FOUND ON THE PART OF THE COMPANY, IT WILL BE LIMITED
                TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL
                THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE EXCLUSION
                OR LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE PRIOR
                LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
              </span>
            </p>
            <p class="c2">
              <span class="c5">17. </span>
              <span class="c0">Termination</span>
            </p>
            <p class="c1">
              <span class="c4">
                We may terminate or suspend your account and bar access to Service immediately,
                without prior notice or liability, under our sole discretion, for any reason
                whatsoever and without limitation, including but not limited to a breach of Terms.
              </span>
            </p>
            <p class="c1">
              <span class="c4">
                If you wish to terminate your account, you may simply discontinue using the Service.
              </span>
            </p>
            <p class="c1">
              <span class="c4">
                All provisions of Terms which by their nature should survive termination shall
                survive termination, including, without limitation, ownership provisions, warranty
                disclaimers, indemnity and limitations of liability.
              </span>
            </p>
            <p class="c1">
              <span class="c4">&nbsp;</span>
            </p>
            <p class="c2">
              <span class="c5">18. </span>
              <span class="c0">Governing Law </span>
            </p>
            <p class="c2">
              <span class="c4">
                These Terms shall be governed and construed per the laws of India, which governing
                law applies to an agreement without regard to its conflict of law provisions.
              </span>
            </p>
            <p class="c1">
              <span class="c4">
                Our failure to enforce any right or provision of these Terms will not be considered
                a waiver of those rights. If any provision of these Terms is held to be invalid or
                unenforceable by a court, the remaining provisions of these Terms will remain in
                effect. These Terms constitute the entire agreement between us regarding our Service
                and supersede and replace any prior agreements we might have had between us
                regarding Service.
              </span>
            </p>
            <p class="c2">
              <span class="c5">19. </span>
              <span class="c0">Changes to Service</span>
            </p>
            <p class="c1">
              <span class="c4">
                We reserve the right to withdraw or amend our Service, and any service or material
                we provide via Service, in our sole discretion without notice. We will not be liable
                if for any reason all or any part of Service is unavailable at any time or for any
                period. From time to time, we may restrict access to some parts of the Service, or
                the entire Service, to users, including registered users.
              </span>
            </p>
            <p class="c2">
              <span class="c5">20. </span>
              <span class="c0">Amendments To Terms</span>
            </p>
            <p class="c1">
              <span class="c4">
                We may amend Terms at any time by posting the amended terms on this site. It is your
                responsibility to review these Terms periodically.
              </span>
            </p>
            <p class="c1">
              <span class="c4">
                Your continued use of the Platform following the posting of revised Terms means that
                you accept and agree to the changes. You are expected to check this page frequently
                so you are aware of any changes, as they are binding on you.
              </span>
            </p>
            <p class="c1">
              <span class="c4">
                By continuing to access or use our Service after any revisions become effective, you
                agree to be bound by the revised terms. If you do not agree to the new terms, you
                are no longer authorized to use Service.
              </span>
            </p>
            <p class="c2">
              <span class="c5">21. </span>
              <span class="c0">Waiver and Severability</span>
            </p>
            <p class="c1">
              <span class="c4">
                No waiver by Company of any term or condition outlined in Terms shall be deemed a
                further or continuing waiver of such term or condition or a waiver of any other term
                or condition, and any failure of Company to assert a right or provision under Terms
                shall not constitute a waiver of such right or provision.
              </span>
            </p>
            <p class="c1">
              <span class="c4">
                If any provision of Terms is held by a court or other tribunal of competent
                jurisdiction to be invalid, illegal or unenforceable for any reason, such provision
                shall be eliminated or limited to the minimum extent such that the remaining
                provisions of Terms will continue in full force and effect.
              </span>
            </p>
            <p class="c2">
              <span class="c5">22. </span>
              <span class="c0">Acknowledgement</span>
            </p>
            <p class="c1">
              <span class="c4">
                BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU HAVE
                READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.
              </span>
            </p>
            <p class="c1">
              <span class="c4">&nbsp;</span>
            </p>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PaymentPolicy;
